html, body {
  height: 100%;
}

body::-webkit-scrollbar {
  border-radius: 1rem;
  width: .5rem;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #0000004d;
}

body::-webkit-scrollbar-thumb {
  background-color: #a9a9a9;
}

body {
  -webkit-user-select: none;
  user-select: none;
  background: url("background.df540c0d.png") 0 0 / 500px;
  width: 100%;
  height: 100%;
  font-family: Cormorant Infant;
}

.row {
  flex-direction: row;
  display: flex;
}

.content {
  backdrop-filter: blur(2px);
  z-index: 1;
  background-color: #fff6;
  margin-bottom: 1rem;
  padding-top: 10rem;
  overflow: hidden auto;
  box-shadow: 0 2px 8px #63636333;
}

@media (width <= 756px) {
  .content {
    margin: 0;
  }
}

.navbar {
  z-index: 999;
  background-color: #fff;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 3.75rem;
  padding: .5rem;
  display: flex;
  position: fixed;
  box-shadow: 0 2px 8px #63636333;
}

.navbar_title {
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  font-size: 1.5rem;
}

.navbar_calendar {
  margin-right: 1.5rem;
}

button {
  color: #333;
  cursor: pointer;
  background-color: #f5f5f5;
  border: 1px solid #d2d2d2;
  border-radius: 6px;
  height: 48px;
  font-size: 16px;
  font-weight: 600;
  transition: background-color .5s;
  box-shadow: 0 4px 10px -2px #0000001a, 0 2px 2px -1px #00000026;
}

button:hover {
  background-color: #fff;
}

.calendar {
  margin-top: 2rem;
}

.header {
  z-index: 2;
  object-fit: contain;
  background-image: url("upl3440.54dbeda8.png");
  background-position: 50%;
  background-repeat: repeat-x;
  background-size: contain;
  width: 80%;
  height: 20%;
  position: absolute;
  top: 40px;
  transform: scale(1.5)rotate(0);
}

section {
  display: flex;
}

.center {
  justify-content: center;
}

.invitation {
  color: #000;
  text-align: justify;
  flex-direction: column;
  align-items: center;
  max-width: 37.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1.5rem;
  display: flex;
}

.invitation h2 {
  text-align: center;
}

.invitation_sign {
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.beating {
  animation: 1.5s infinite beat;
}

@keyframes beat {
  0%, 50%, 100% {
    transform: scale(1);
  }

  30%, 80% {
    transform: scale(.92, .95);
  }
}

iframe {
  margin-top: 1rem;
}

.column {
  flex-direction: column;
  align-items: center;
}

.timeline {
  gap: 50px;
  width: 100%;
  max-width: 600px;
  margin-bottom: 10px;
  display: flex;
}

.timeline_date {
  text-align: left;
  width: 120px;
}

.timeline_data {
  text-align: left;
  width: 480px;
}

section .navbar_calendar {
  margin-top: 1rem;
}

.text {
  font-size: 1.25rem;
}

.padding-1 {
  padding: 1rem;
}

.spikes {
  background: #fff;
  margin-top: 2rem;
  position: relative;
}

.spikes:before {
  content: "";
  z-index: 10;
  background-image: linear-gradient(135deg, #fff 25%, #0000 25%), linear-gradient(225deg, #fff 25%, #0000 25%);
  background-position: 0 0;
  background-size: .75rem 100%;
  height: .75rem;
  display: block;
  position: absolute;
  top: -.75rem;
  left: -22%;
  right: 0;
  transform: rotateX(180deg);
}

.gray-filter {
  -webkit-filter: grayscale();
  -ms-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -o-filter: grayscale(1);
}

.color-palete {
  scroll-behavior: smooth;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  width: 100%;
  max-width: 800px;
  padding: 1rem;
  display: flex;
  overflow: auto;
}

.description {
  max-width: 600px;
}

.circle {
  border: 1px solid #dcdcdc;
  border-radius: 100px;
  flex-shrink: 0;
  width: 100px;
  height: 100px;
  margin: auto;
  transition: transform .3s;
  box-shadow: 0 3px 3px #00000026;
}

.scalable {
  cursor: pointer;
}

.scalable:hover {
  transform: scale(1.2);
}

.grey {
  background: #e4e7e6;
}

.hacky {
  background: #d6d4ad;
}

.yellow {
  background: #fbe09c;
}

.pink {
  background: #eddada;
}

.rose {
  background: #cb8587;
}

.quiz {
  align-self: start;
  margin-left: 2rem;
}

.quiz_img {
  position: absolute;
  bottom: 0;
  right: 0;
}

.relative {
  position: relative;
}

.question {
  margin-bottom: 1.5rem;
}

.question_text {
  margin-bottom: .5rem;
  font-size: 1.25rem;
  font-weight: 600;
}

.question_answer {
  flex-direction: column;
  gap: .75rem;
  max-width: 400px;
  display: flex;
}

.postscriptum {
  max-width: 800px;
}

input {
  accent-color: #bb8845;
  width: 1rem;
  height: 1rem;
}

.logo {
  border-radius: 128px;
  width: 300px;
  height: 256px;
  margin-top: 2rem;
}

.easteregg-1 {
  margin-top: 2rem;
  transform: rotateY(180deg);
}

.easteregg {
  border-radius: 128px;
  width: 256px;
  height: 256px;
  margin-top: 2rem;
}

.join-btn {
  color: #fff;
  background: #229ed9;
  border-color: #1b80b1;
  width: 256px;
  height: 48px;
  margin-top: 2rem;
  transition: background .5s;
  position: relative;
}

.join-btn:hover {
  background: #1b80b1;
}

.divider {
  background-image: url("divider.3a95cd43.jpg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100px;
}

.divider--upside-down {
  transform: rotateX(180deg);
}

.handdrawn-horizontal-arrows:after {
  content: "";
  background-image: url("left-arrow1.d6b6742b.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 150px;
  height: 50px;
  position: absolute;
  top: -64px;
  right: -145px;
  transform: rotateZ(325deg);
}

.footer {
  background: #fff;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  display: flex;
}

.footer_text {
  font-size: 1.25rem;
}
/*# sourceMappingURL=index.866f77e0.css.map */
