html,
body {
    height: 100%;
}

body::-webkit-scrollbar {
    width: 0.5rem;
    border-radius: 1rem;
}

body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
    background-color: darkgrey;
}

body {
    background: #eff0f5;
    background: url('assets/background.png');
    background-size: 500px;
    width: 100%;
    height: 100%;
    font-family: 'Cormorant Infant';
    user-select: none;
}

.row {
    display: flex;
    flex-direction: row;
}

.content {
    padding-top: 10rem;
    background-color: rgba(255, 255, 255, 0.4);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    // margin-left: 15%;
    // margin-right: 15%;
    overflow: hidden;
    overflow-y: auto;
    z-index: 1;
    margin-bottom: 1rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}


@media (width <=756px) {
    .content {
        margin: 0;
    }
}


.navbar {
    position: fixed;
    display: flex;
    flex-grow: 1;
    z-index: 999;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 3.75rem;
    background-color: white;
    padding: 0.5rem;

    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    &_title {
        font-size: 1.5rem;
        text-transform: uppercase;
        width: 100%;
        text-align: center;
    }

    &_calendar {
        margin-right: 1.5rem;
    }
}


button {
    border: none;
    border-radius: 6px;
    font-weight: 600;
    font-size: 16px;
    color: #333;
    cursor: pointer;
    background-color: #f5f5f5;
    border: 1px solid #d2d2d2;
    transition: background 0.5s;
    box-shadow: rgba(0 0 0 / 10%) 0 4px 10px -2px, rgba(0 0 0 / 15%) 0 2px 2px -1px;
    height: 48px;

    transition: background-color 0.5s;

    &:hover {
        background-color: #fff;
    }
}

.calendar {
    margin-top: 2rem;
}



.header {
    background-image: url('assets/upl3440.png');
    transform: scale(1.5) rotate(0deg);
    -webkit-transform: scale(1.5) rotate(0deg);
    -moz-transform: scale(1.5) rotate(0deg);
    -o-transform: scale(1.5) rotate(0deg);
    -ms-transform: scale(1.5) rotate(0deg);
    top: 40px;
    width: 80%;
    height: 20%;
    background-repeat: repeat-x;
    background-size: contain;
    background-position: 50%;
    position: absolute;
    z-index: 2;
    object-fit: contain;
}

section {
    display: flex;
}

.center {
    justify-content: center;
}

.invitation {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 37.5rem;
    font-size: 1.5rem;
    color: rgb(0, 0, 0);
    text-align: justify;
    padding-left: 1rem;
    padding-right: 1rem;

    h2 {
        text-align: center;
    }

    &_sign {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }
}

.beating {
    animation: 1.5s ease 0s infinite beat;
}

@keyframes beat {

    0%,
    50%,
    100% {
        transform: scale(1, 1);
    }

    30%,
    80% {
        transform: scale(0.92, 0.95);
    }
}

iframe {
    margin-top: 1rem;
}

.column {
    flex-direction: column;
    align-items: center;
}

.timeline {
    display: flex;
    gap: 50px;
    max-width: 600px;
    width: 100%;
    margin-bottom: 10px;


    &_date {
        text-align: left;
        width: 120px;
    }

    &_data {
        text-align: left;
        width: 480px;
    }
}


section .navbar_calendar {
    margin-top: 1rem;
}

.text {
    font-size: 1.25rem;
}

.padding-1 {
    padding: 1rem;
}

.spikes {
    position: relative;
    background: #ffffff;
    margin-top: 2rem;

    &::before {
        content: '';
        position: absolute;
        right: 0;
        left: -22%;
        top: -0.75rem;
        z-index: 10;
        display: block;
        height: 0.75rem;
        transform: rotateX(180deg);
        background-size: 0.75rem 100%;
        background-image: linear-gradient(135deg, #ffffff 25%, transparent 25%), linear-gradient(225deg, #ffffff 25%, transparent 25%);
        background-position: 0 0;
    }
}



.gray-filter {
    filter: grayscale(1);
    -ms-filter: grayscale(1);
    -webkit-filter: grayscale(1);
    -moz-filter: grayscale(1);
    -o-filter: grayscale(1);
}

.color-palete {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    width: 100%;
    overflow: auto;
    scroll-behavior: smooth;
    padding: 1rem;
    max-width: 800px;
}

.description {
    max-width: 600px;
}

.circle {
    margin: auto;
    border-radius: 100px;
    border: 1px solid gainsboro;
    width: 100px;
    height: 100px;
    flex-shrink: 0;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
    transition: transform .3s;
}

.scalable {
    cursor: pointer;

    &:hover {
        transform: scale(1.2);
    }
}

.grey {
    background: #E4E7E6;
}

.hacky {
    background: #D6D4AD;
}

.yellow {
    background: #FBE09C;
}

.pink {
    background: #EDDADA;
}

.rose {
    background: #CB8587;
}

.quiz {
    align-self: start;
    margin-left: 2rem;


    &_img {
        position: absolute;
        bottom: 0;
        right: 0;
    }
}

.relative {
    position: relative;
}

.question {
    margin-bottom: 1.5rem;

    &_text {
        margin-bottom: 0.5rem;
        font-size: 1.25rem;
        font-weight: 600;
    }

    &_answer {
        max-width: 400px;
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
    }
}

.postscriptum {
    max-width: 800px;
}

input {
    accent-color: rgb(187, 136, 69);
    width: 1rem;
    height: 1rem;
}

.logo {
    width: 300px;
    height: 256px;
    border-radius: 128px;
    margin-top: 2rem;
}


.easteregg-1 {
    transform: rotateY(180deg);
    margin-top: 2rem;
}

.easteregg {
    width: 256px;
    height: 256px;
    border-radius: 128px;
    margin-top: 2rem;
}

.join-btn {
    width: 256px;
    height: 48px;
    background: #229ED9;
    color: white;
    border-color: #1b80b1;
    margin-top: 2rem;
    transition: background 0.5s;
    position: relative;

    &:hover {
        background: #1b80b1;
    }
}


.divider {
    height: 100px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
    background-image: url("assets/divider.jpg");
    // margin-top: 5rem;
}

.divider--upside-down {
    transform: rotateX(180deg);
}


.handdrawn-horizontal-arrows:after {
    content: '';
    background-image: url("assets/left-arrow1.png");
    width: 150px;
    height: 50px;
    top: -64px;
    right: -145px;
    transform: rotateZ(325deg);
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
}



.footer {
    width: 100%;
    height: 40px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;

    &_text {
        font-size: 1.25rem;
    }

}